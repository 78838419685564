<template>
  <div id="information" class="container">
    <div class="user-info d-flex mb-4 align-items-center">
      <div class="d-flex flex-column">
        <img class="me-3 border-radius user-img" :src="user.Image ? user.Image : require('../../assets/images/profile/user_Profile.svg')" alt="" style="width: 140px; height: 140px" />
        <img src="../../assets/icons/nkicon/edit_profile.svg" class="edit-profile" @click="changePhotoDialog = true" />
      </div>
      <div>
        <div v-if="user.IsCarrier">
          <img src="../../assets/icons/nkicon/star.svg" alt="" style="margin-right: 5px" />
          {{ user.AvgRate ? Math.round(user.AvgRate * 10) / 10 : "Henüz değerlendirilmemiş." }}
        </div>
        <div class="user-name fs-20 fw-bold">
          <span>{{ user.FirstName + " " + user.LastName }}</span>
        </div>
        <!-- <div class="user-city mb-2">
          <span v-if="user.City">{{ user.City.Title }}</span> <span>/</span>
          <span v-if="user.District">{{ user.District.Title }}</span>
        </div> -->
        <div class="information-btns">
          <el-button class="bg-blue border-transparent p-3" type="text" @click="passwordDialog = true"> Şifre Değiştirme</el-button>
        </div>
      </div>
    </div>

    <b-tabs content-class="mt-3" v-model="step" justified>
      <b-tab title="Bilgilerim">
        <div class="card-body">
          <div class="basic-form">
            <form>
              <div class="row">
                <div class="mb-3 col-md-6">
                  <label class="form-label">İsim</label>
                  <el-input
                    class="form-control"
                    :class="{
                      'input-validation-error': userResponse.ValidationErrors.some((x) => x.Key == 'FirstName'),
                    }"
                    placeholder="..."
                    v-model="userFormInputs.FirstName"
                  ></el-input>
                  <span class="validation-error" v-if="userResponse.ValidationErrors.some((x) => x.Key == 'FirstName')">{{ userResponse.ValidationErrors.filter((x) => x.Key == "FirstName")[0].Value }}</span>
                </div>
                <div class="mb-3 col-md-6">
                  <label class="form-label">Soyisim</label>
                  <el-input
                    class="form-control"
                    :class="{
                      'input-validation-error': userResponse.ValidationErrors.some((x) => x.Key == 'LastName'),
                    }"
                    placeholder="..."
                    v-model="userFormInputs.LastName"
                  ></el-input>
                  <span class="validation-error" v-if="userResponse.ValidationErrors.some((x) => x.Key == 'LastName')">{{ userResponse.ValidationErrors.filter((x) => x.Key == "LastName")[0].Value }}</span>
                </div>
                <div class="mb-4 col-md-6">
                  <label class="form-label">E-posta</label>
                  <el-input
                    class="form-control"
                    :class="{
                      'input-validation-error': userResponse.ValidationErrors.some((x) => x.Key == 'Email'),
                    }"
                    placeholder="birisi@ornek.com"
                    v-model="userFormInputs.Email"
                  ></el-input>
                  <span class="validation-error" v-if="userResponse.ValidationErrors.some((x) => x.Key == 'Email')">{{ userResponse.ValidationErrors.filter((x) => x.Key == "Email")[0].Value }}</span>
                </div>
                <div class="mb-3 col-md-6">
                  <label class="form-label">Telefon</label>
                  <el-input
                    class="form-control"
                    :class="{
                      'input-validation-error': userResponse.ValidationErrors.some((x) => x.Key == 'PhoneNumber'),
                    }"
                    v-mask="['+90 (###) ### ## ##']"
                    placeholder="+90 (xxx) xxx xx xx"
                    v-model="userFormInputs.PhoneNumber"
                  ></el-input>
                  <span class="validation-error" v-if="userResponse.ValidationErrors.some((x) => x.Key == 'PhoneNumber')">{{ userResponse.ValidationErrors.filter((x) => x.Key == "PhoneNumber")[0].Value }}</span>
                </div>
                <!-- <div class="mb-3 col-md-6">
                  <label>İl</label>
                  <el-select
                    class="default-select form-control wide"
                    :class="{
                      'input-validation-error':
                        userResponse.ValidationErrors.some(
                          (x) => x.Key == 'CityTitle'
                        ),
                    }"
                    v-model="userFormInputs.CityTitle"
                    @change="handleCityChange"
                    placeholder="..."
                  >
                    <el-option
                      v-for="item in $store.state.cityDistrict.cities"
                      :key="item.ID"
                      :label="item.Title"
                      :value="item.ID"
                    >
                    </el-option>
                  </el-select>
                  <span
                    class="validation-error"
                    v-if="
                      userResponse.ValidationErrors.some(
                        (x) => x.Key == 'CityTitle'
                      )
                    "
                    >{{
                      userResponse.ValidationErrors.filter(
                        (x) => x.Key == "CityTitle"
                      )[0].Value
                    }}</span
                  >
                </div> -->
                <!-- <div class="mb-4 col-md-6">
                  <label>İlçe</label>
                  <el-select
                    class="default-select form-control wide"
                    :class="{
                      'input-validation-error':
                        userResponse.ValidationErrors.some(
                          (x) => x.Key == 'DistrictTitle'
                        ),
                    }"
                    v-model="userFormInputs.DistrictTitle"
                    placeholder="..."
                  >
                    <el-option
                      v-for="item in personalInfoDistricts"
                      :key="item.ID"
                      :label="item.Title"
                      :value="item.ID"
                    >
                    </el-option>
                  </el-select>

                  <span
                    class="validation-error"
                    v-if="
                      userResponse.ValidationErrors.some(
                        (x) => x.Key == 'DistrictTitle'
                      )
                    "
                    >{{
                      userResponse.ValidationErrors.filter(
                        (x) => x.Key == "DistrictTitle"
                      )[0].Value
                    }}</span
                  >
                </div> -->

                <div class="col-6">
                  <el-button class="bg-yellow border-transparent py-3 w-100" type="text" @click="invoiceDialog = true"> Fatura Bilgileri</el-button>
                </div>
                <div class="col-6">
                  <el-button :loading="loading" class="bg-primary py-3 w-100" @click="updateUserInfo">Kaydet</el-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-tab>

      <b-tab v-if="user.IsCarrier && false" title="Araçlarım">
        <div class="row">
          <div
            class="
              col-md-12
              d-flex
              justify-content-between
              align-items-center
              depots
              bg-light
              border-radius
              py-2
              px-3
              mb-4
            "
            v-for="(item, index) in $store.state.User.careerVehicles"
            :key="index"
          >
            <div class="depot-name fs-16">
              {{ item.Brand + "/" + item.Model + "-" + item.PlateNo }}
            </div>
            <div class="d-flex">
              <span v-if="item.Status === 1" class="badge badge-confirm">
                Onay Bekliyor
              </span>
              <el-button
                circle
                @click="
                  () => {
                    VehicleForm = getCopiedObject(item);
                    vehicleDialog = true;
                    isUpdate = true;
                    active = 2;
                    selectedVehicleType = item.Type;
                  }
                "
                ><img width="20px" src="../../assets/icons/nkicon/adddetail.svg" alt=""
              /></el-button>
              <el-button
                circle
                @click="
                  () => {
                    deleteVehicleDialog = true;
                    vehicleId = item.ID;
                  }
                "
                ><img src="../../assets/images/delete.svg" alt=""
              /></el-button>
            </div>
          </div>

          <a
            href="javascript:;"
            @click="addVehicleDialog"
            class="
              col-md-12
              d-flex
              justify-content-between
              align-items-center
              add-depot
              bg-primary
              border-radius
              py-2
              px-3
              mb-4
            "
          >
            <div>
              <span class="fs-16">Yeni Bir Araç Ekle</span>
            </div>
            <div>
              <img src="../../assets/images/add.svg" alt="" />
            </div>
          </a>
        </div>
      </b-tab>

      <b-tab v-if="user.IsCarrier && false" title="Banka Hesap Bilgilerim">
        <div class="row">
          <div
            class="
              col-md-12
              d-flex
              justify-content-between
              align-items-center
              depots
              border-radius
              py-2
              px-3
              mb-4
            "
          >
            <p class="text-blue">
              Bu sayfada girdiğiniz banka hesap bilgileri, ödeme yöntemi GÖÇ ve havale olan sevkiyatlarınızda sadece tedarikçi tarafından görüntülenir.
            </p>
          </div>

          <div class="col-lg-6 mb-2">
            <div class="mb-3">
              <label class="text-label form-label">Banka Adı</label>
              <el-input
                class="form-control"
                :class="{
                  'input-validation-error': bankResponse.ValidationErrors.some((x) => x.Key == 'Title'),
                }"
                placeholder="(Örn. Ziraat Bankası)"
                maxlength="100"
                v-model="userFormInputs.BankAccount.Title"
              ></el-input>
              <span class="validation-error" v-if="bankResponse.ValidationErrors.some((x) => x.Key == 'Title')">{{ bankResponse.ValidationErrors.filter((x) => x.Key == "Title")[0].Value }}</span>
            </div>
          </div>

          <div class="col-lg-6 mb-2">
            <div class="mb-3">
              <label class="text-label form-label">IBAN</label>
              <el-input
                class="form-control"
                :class="{
                  'input-validation-error': bankResponse.ValidationErrors.some((x) => x.Key == 'IBAN'),
                }"
                v-mask="['TR## #### #### #### #### #### ##']"
                placeholder="TR.. .... .... .... .... .... .."
                v-model="userFormInputs.BankAccount.IBAN"
                @keypress.enter.native="updateBankInfo"
              ></el-input>
              <span class="validation-error" v-if="bankResponse.ValidationErrors.some((x) => x.Key == 'IBAN')">{{ bankResponse.ValidationErrors.filter((x) => x.Key == "IBAN")[0].Value }}</span>
            </div>
          </div>
          <div class="col-md-12 text-end">
            <el-button :loading="loading" @click="updateBankInfo" class="bg-primary py-3 px-5">Kaydet</el-button>
          </div>
        </div>
      </b-tab>

      <b-tab  v-if="!user.IsCarrier && false" title="Depolarım"
        @click="
          () => {
            isWarehouse = true;
            this.firstRenderWarehouseDistrict = true;
          }
        "
      >
        <div class="row">
          <div
            class="
              col-md-12
              d-flex
              justify-content-between
              align-items-center
              depots
              bg-light
              border-radius
              py-2
              px-3
              mb-4
            "
            v-for="(item, index) in $store.state.User.warehouses"
            :key="index"
          >
            <div class="depot-name fs-16">{{ item.Title }}</div>
            <div class="d-flex">
              <el-button circle @click="selectWarehouse(item)"><img src="../../assets/images/edit.svg" alt=""/></el-button>
              <el-button
                circle
                @click="
                  () => {
                    deleteDialog = true;
                    warehouseId = item.ID;
                  }
                "
                ><img src="../../assets/images/delete.svg" alt=""
              /></el-button>
            </div>
          </div>

          <a
            href="javascript:;"
            @click="newWarehouse"
            class="
              col-md-12
              d-flex
              justify-content-between
              align-items-center
              add-depot
              bg-primary
              border-radius
              py-2
              px-3
              mb-4
            "
          >
            <div>
              <span class="fs-16">Yeni Bir Depo Ekle</span>
            </div>
            <div>
              <img src="../../assets/images/add.svg" alt="" />
            </div>
          </a>
        </div>
      </b-tab>

      <b-tab  v-if="!user.IsCarrier && false"  title="Teslimat Firmalarım"
        @click="
          () => {
            isWarehouse = false;
            this.firstRenderWarehouseDistrict = true;
          }
        "
      >
        <div class="row">
          <div
            class="
              col-md-12
              d-flex
              justify-content-between
              align-items-center
              depots
              bg-light
              border-radius
              py-2
              px-3
              mb-4
            "
            v-for="(item, index) in $store.state.User.deliveryCompanies"
            :key="index"
          >
            <div class="depot-name fs-16">{{ item.Title }}</div>
            <div class="d-flex">
              <el-button circle @click="selectWarehouse(item)"><img src="../../assets/images/edit.svg" alt=""/></el-button>
              <el-button
                circle
                @click="
                  () => {
                    deleteDialog = true;
                    warehouseId = item.ID;
                  }
                "
                ><img src="../../assets/images/delete.svg" alt=""
              /></el-button>
            </div>
          </div>

          <a
            href="javascript:;"
            @click="newWarehouse"
            class="
              col-md-12
              d-flex
              justify-content-between
              align-items-center
              add-depot
              bg-primary
              border-radius
              py-2
              px-3
              mb-4
            "
          >
            <div>
              <span class="fs-16">Yeni Bir Teslimat Noktası Ekle</span>
            </div>
            <div>
              <img src="../../assets/images/add.svg" alt="" />
            </div>
          </a>
        </div>
      </b-tab>

      <b-tab title="Bildirim Ayarlarım">
        <div
          class="
            col-md-12
            d-flex
            justify-content-between
            align-items-center
            notifications
            bg-light
            border-radius
            py-2
            px-3
            mb-4
          "
        >
          <div class="depot-name fs-16">Yeni bir teklif</div>
          <div>
            <el-switch v-model="NotificationSettingsInputs.NewOffer" active-color="#E9501C" inactive-color="#2D405A"> </el-switch>
          </div>
        </div>

        <div
          class="
            col-md-12
            d-flex
            justify-content-between
            align-items-center
            notifications
            bg-light
            border-radius
            py-2
            px-3
            mb-4
          "
        >
          <div class="depot-name fs-16">Sevkiyat tamamlanması</div>
          <div>
            <el-switch v-model="NotificationSettingsInputs.ShippingCompleted" active-color="#E9501C" inactive-color="#2D405A"> </el-switch>
          </div>
        </div>

        <div
          class="
            col-md-12
            d-flex
            justify-content-between
            align-items-center
            notifications
            bg-light
            border-radius
            py-2
            px-3
            mb-4
          "
        >
          <div class="depot-name fs-16">Sevkiyat teslim edilmesi</div>
          <div>
            <el-switch v-model="NotificationSettingsInputs.ShippingDelivered" active-color="#E9501C" inactive-color="#2D405A"> </el-switch>
          </div>
        </div>

        <div
          class="
            col-md-12
            d-flex
            justify-content-between
            align-items-center
            notifications
            bg-light
            border-radius
            py-2
            px-3
            mb-4
          "
        >
          <div class="depot-name fs-16">İlan süresi bitimi</div>
          <div>
            <el-switch v-model="NotificationSettingsInputs.AdvertsExpired" active-color="#E9501C" inactive-color="#2D405A"> </el-switch>
          </div>
        </div>

        <div
          class="
            col-md-12
            d-flex
            justify-content-between
            align-items-center
            notifications
            bg-light
            border-radius
            py-2
            px-3
            mb-4
          "
        >
          <div class="depot-name fs-16">Mağaza indirimleri ve kampanyalar</div>
          <div>
            <el-switch v-model="NotificationSettingsInputs.Campaigns" active-color="#E9501C" inactive-color="#2D405A"> </el-switch>
          </div>
        </div>

        <div
          class="
            col-md-12
            d-flex
            justify-content-between
            align-items-center
            notifications
            bg-light
            border-radius
            py-2
            px-3
            mb-4
          "
        >
          <div class="depot-name fs-16">Profile yorum yapılması</div>
          <div>
            <el-switch v-model="NotificationSettingsInputs.NewComment" active-color="#E9501C" inactive-color="#2D405A"> </el-switch>
          </div>
        </div>

        <div
          class="
            col-md-12
            d-flex
            justify-content-between
            align-items-center
            notifications
            bg-light
            border-radius
            py-2
            px-3
            mb-4
          "
        >
          <div class="depot-name fs-16">Uygun bir ilan</div>
          <div>
            <el-switch v-model="NotificationSettingsInputs.NewAdvert" active-color="#E9501C" inactive-color="#2D405A"> </el-switch>
          </div>
        </div>
      </b-tab>

      <b-tab title="Arkadaş Davet Et">
        <div class="row">
          <div class="col-md-12 depots border-radius py-2 px-3 mb-4">
            <p class="text-white">
              Arkadaşını davet ederek hediye NAKU kazanmak ister misin?
            </p>
            <p class="text-blue mb-2">
              Uygulamada olmasını düşündüğün ve burada görmek istediğin arkadaşını e-posta adresi ya da telefonuyla davet ederek hem NAKU kazanabilir hem de arkadaşına NAKU kazandırabilirsin!
            </p>
            <p class="text-blue">
              Hemen davet et ve sınırlı sayıdaki fırsattan yararlan!
            </p>
            <label>E-posta</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': inviteFriendResponse.ValidationErrors.some((x) => x.Key == 'Email'),
              }"
              placeholder="birisi@ornek.com"
              maxlength="60"
              v-model="invitecode"
              @keypress.enter.native="inviteFriend"
            ></el-input>
            <span class="validation-error" v-if="inviteFriendResponse.ValidationErrors.some((x) => x.Key == 'Email')">{{ inviteFriendResponse.ValidationErrors.filter((x) => x.Key == "Email")[0].Value }}</span>
          </div>

          <div class="col-md-12 text-end">
            <el-button :loading="loading" @click="inviteFriend" class="bg-primary py-3 px-5">Arkadaşını Davet Et</el-button>
          </div>
        </div>
      </b-tab>

      <b-tab title="Engellediklerim">
        <not-found-info :item="blockedUsers" :title="'Engellenen kullanıcı'" :emptyName="'blocked'" />
        <div v-if="blockedUsers.length > 0" class="row">
          <div class="col-md-6 mb-3" v-for="(item, index) in user.BlockedUsers" :key="index">
            <div class="block-info bg-light border-radius">
              <div class="d-flex justify-content-between mb-3">
                <div class="d-flex align-items-center">
                  <img class="border-radius me-3" :src="item.Image ? item.Image : require('../../assets/images/profile/user_Profile.svg')" alt="" style="width: 150px; filter: blur(4px)" />
                  <div>
                    <span class="block-date">{{ $moment(item.CreateDate).format("LL") }}</span>
                    <br />
                    <span class="block-user fs-18 fw-bold">
                      {{
                        (
                          item.FullName.substring(0, 1) +
                          Array(item.FullName ? item.FullName.length - 1 : 0)
                            .fill("*")
                            .join("")
                        ).substring(0, 10)
                      }}
                    </span>
                    <br />
                  </div>
                </div>
              </div>
              <el-button class="border-transparent p-3" type="text" @click="handleRemoveBlock(item.UserId)"> Engeli Kaldır</el-button>
            </div>
          </div>
        </div>
      </b-tab>

      <b-tab v-if="user.IsCarrier" title="Şehir ve Çalışma Güzergahları">
        <div class="row" style="padding:0 255px;">
     
          <div class="col-lg-6 col-6" style="margin-top: 13px;">
          <el-select v-model="userFormInputs.WorkCityId" class="form-control" placeholder="Güzergah Şehri" filterable clearable @clear="clearCityId('warehouse')">
            <el-option v-for="(item, index) in filteredCities()" :key="index" :label="item.Title" :value="item.ID"> </el-option>
          </el-select>
        </div>
        <div class="col-lg-6 col-6 ps-3" style="margin-top: 13px;margin-bottom: 10px;">
          <el-select v-model="userFormInputs.TargetWorkCityId" class="form-control" placeholder="Güzergah Şehri 2" filterable clearable @clear="clearCityId('delivery')">
            <el-option v-for="(item, index) in filteredCities()" :key="index" :label="item.Title" :value="item.ID"> </el-option>
          </el-select>
        </div>
        <div class="col-md-4 d-flex justify-content-center" @click="selectVehicleType(1)" style="min-height: 100px" :class="userFormInputs.VehicleType === 1 ? 'select-active' : null">
          <a class="p-3 d-flex flex-column text-center w-100 h-100" href="javascript:;">
            <img class="p-4 border border-radius bg-light mb-3" style="min-height: 150px" src="../../assets/images/kamyon.svg" alt="" />
            <span class="text-white">Kamyon</span>
          </a>
        </div>

        <div class="col-md-4 d-flex justify-content-center" @click="selectVehicleType(0)" style="min-height: 100px" :class="userFormInputs.VehicleType === 0 ? 'select-active' : null">
          <a class="p-3 d-flex flex-column text-center w-100 h-100" href="javascript:;">
            <img class="p-4 border border-radius bg-light mb-3" style="min-height: 150px" src="../../assets/images/tir.svg" alt="" />
            <span class="text-white">Tır</span>
          </a>
        </div>
        <div class="col-md-4 d-flex justify-content-center" @click="selectVehicleType(2)" style="min-height: 100px" :class="userFormInputs.VehicleType === 2 ? 'select-active' : null">
          <a class="p-3 d-flex flex-column text-center w-100 h-100" href="javascript:;">
            <img class="p-4 border border-radius bg-light mb-3" style="min-height: 150px" src="../../assets/images/kamyonet-kasali.svg" alt="" />
            <span class="text-white">Kamyonet</span>
          </a>
        </div>
        <div class="row" style="margin-top:15px"> <div class="col-6"></div>
        <div class="col-6">
                  <el-button :loading="loading" class="bg-primary py-3 w-100" @click="updateUserInfo">Kaydet</el-button>
                </div></div>
       
      </div>
      </b-tab>
    </b-tabs>
    <el-dialog :close-on-click-modal="false" title="Profil Fotoğrafını Değiştir" :visible.sync="changePhotoDialog">
      <div class="row">
        <div class="col-md-12 text-center mb-4">
          <el-upload
            v-if="token"
            class="avatar-uploader"
            :action="'https://api.nakliyekullan.com/' +  'Account/UploadImage'"
            :headers="{
              Authorization: 'Bearer ' + token,
            }"
            :show-file-list="false"
            :on-success="handleChangePhoto"
          >
            <img v-if="user.Image" :src="user.Image ? user.Image : require('../../assets/images/profile/user_Profile.svg')" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" title="Fatura Bilgisi Düzenle" :visible.sync="invoiceDialog" :before-close="clearInvoiceResponse">
      <span>
        <div class="row">
          <!-- <div class="col-md-12 text-center mb-4">
            <el-upload
              v-if="token"
              class="avatar-uploader"
              :action="'https://api.nakliyekullan.com/' +  'Account/UploadImage'"
              :headers="{
                Authorization: 'Bearer ' + token,
              }"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img
                v-if="BillForm.CompanyLogo"
                :src="BillForm.CompanyLogo"
                class="avatar"
                :class="{
                  'input-validation-error': invoiceResponse.ValidationErrors.some((x) => x.Key == 'CompanyLogo'),
                }"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <span class="validation-error" v-if="invoiceResponse.ValidationErrors.some((x) => x.Key == 'CompanyLogo')">{{ invoiceResponse.ValidationErrors.filter((x) => x.Key == "CompanyLogo")[0].Value }}</span>
          </div> -->
          <div class="col-lg-12 mb-2">
            <div class="mb-3">
              <label class="text-label form-label">Firma Adı</label>
              <el-input
                class="form-control"
                :class="{
                  'input-validation-error': invoiceResponse.ValidationErrors.some((x) => x.Key == 'Title'),
                }"
                placeholder="Lütfen Firma Adı Giriniz"
                v-model="BillForm.Title"
              ></el-input>
              <span class="validation-error" v-if="invoiceResponse.ValidationErrors.some((x) => x.Key == 'Title')">{{ invoiceResponse.ValidationErrors.filter((x) => x.Key == "Title")[0].Value }}</span>
            </div>
          </div>

          <div class="col-lg-6 mb-2">
            <div class="mb-3">
              <label class="text-label form-label">E-posta</label>
              <el-input
                class="form-control"
                :class="{
                  'input-validation-error': invoiceResponse.ValidationErrors.some((x) => x.Key == 'Email'),
                }"
                placeholder="birisi@ornek.com"
                v-model="BillForm.Email"
              ></el-input>
              <span class="validation-error" v-if="invoiceResponse.ValidationErrors.some((x) => x.Key == 'Email')">{{ invoiceResponse.ValidationErrors.filter((x) => x.Key == "Email")[0].Value }}</span>
            </div>
          </div>

          <div class="col-lg-6 mb-2">
            <div class="mb-3">
              <label class="text-label form-label">Telefon</label>
              <el-input
                class="form-control"
                :class="{
                  'input-validation-error': invoiceResponse.ValidationErrors.some((x) => x.Key == 'PhoneNumber'),
                }"
                v-mask="['+90 (###) ### ## ##']"
                placeholder="+90 (xxx) xxx xx xx"
                :minlength="13"
                v-model="BillForm.PhoneNumber"
              ></el-input>
              <span class="validation-error" v-if="invoiceResponse.ValidationErrors.some((x) => x.Key == 'PhoneNumber')">{{ invoiceResponse.ValidationErrors.filter((x) => x.Key == "PhoneNumber")[0].Value }}</span>
            </div>
          </div>
          <!-- <div class="col-lg-6 mb-2">
            <div class="mb-3">
              <label class="text-label form-label">İl</label>
              <el-select
                class="default-select form-control wide"
                :class="{
                  'input-validation-error':
                    invoiceResponse.ValidationErrors.some(
                      (x) => x.Key == 'CityTitle'
                    ),
                }"
                v-model="BillForm.CityTitle"
                placeholder="..."
              >
                <el-option
                  v-for="item in $store.state.cityDistrict.cities"
                  :key="item.ID"
                  :label="item.Title"
                  :value="item.ID"
                >
                </el-option>
              </el-select>
              <span
                class="validation-error"
                v-if="
                  invoiceResponse.ValidationErrors.some(
                    (x) => x.Key == 'CityTitle'
                  )
                "
                >{{
                  invoiceResponse.ValidationErrors.filter(
                    (x) => x.Key == "CityTitle"
                  )[0].Value
                }}</span
              >
            </div>
          </div>

          <div class="col-lg-6 mb-3">
            <div class="mb-3">
              <label class="text-label form-label">İlçe</label>
              <el-select
                class="default-select form-control wide"
                :class="{
                  'input-validation-error':
                    invoiceResponse.ValidationErrors.some(
                      (x) => x.Key == 'DistrictTitle'
                    ),
                }"
                v-model="BillForm.DistrictTitle"
                placeholder="..."
              >
                <el-option
                  v-for="item in billFormDistricts"
                  :key="item.ID"
                  :label="item.Title"
                  :value="item.ID"
                >
                </el-option>
              </el-select>
              <span
                class="validation-error"
                v-if="
                  invoiceResponse.ValidationErrors.some(
                    (x) => x.Key == 'DistrictTitle'
                  )
                "
                >{{
                  invoiceResponse.ValidationErrors.filter(
                    (x) => x.Key == "DistrictTitle"
                  )[0].Value
                }}</span
              >
            </div>
          </div> -->

          <div class="col-lg-6 mb-2">
            <div class="mb-3">
              <label class="text-label form-label">Vergi Numarası</label>
              <el-input
                class="form-control"
                :class="{
                  'input-validation-error': invoiceResponse.ValidationErrors.some((x) => x.Key == 'TaxNo'),
                }"
                placeholder="Lütfen Vergi Numarası Giriniz"
                maxlength="10"
                v-model="BillForm.TaxNo"
              ></el-input>
              <span class="validation-error" v-if="invoiceResponse.ValidationErrors.some((x) => x.Key == 'TaxNo')">{{ invoiceResponse.ValidationErrors.filter((x) => x.Key == "TaxNo")[0].Value }}</span>
            </div>
          </div>

          <div class="col-lg-6 mb-2">
            <div class="mb-3">
              <label class="text-label form-label">Vergi Dairesi</label>
              <el-input
                class="form-control"
                :class="{
                  'input-validation-error': invoiceResponse.ValidationErrors.some((x) => x.Key == 'TaxOffice'),
                }"
                placeholder="Lütfen Vergi Dairesi Giriniz"
                v-model="BillForm.TaxOffice"
              ></el-input>
              <span class="validation-error" v-if="invoiceResponse.ValidationErrors.some((x) => x.Key == 'TaxOffice')">{{ invoiceResponse.ValidationErrors.filter((x) => x.Key == "TaxOffice")[0].Value }}</span>
            </div>
          </div>

          <div class="mb-3 col-md-12">
            <label class="form-label">Adres</label>
            <textarea
              class="form-control"
              :class="{
                'input-validation-error': invoiceResponse.ValidationErrors.some((x) => x.Key == 'Address'),
              }"
              type="textarea"
              :rows="2"
              placeholder="Lütfen Adresinizi Giriniz"
              v-model="BillForm.Address"
            >
            </textarea>
            <span class="validation-error" v-if="invoiceResponse.ValidationErrors.some((x) => x.Key == 'Address')">{{ invoiceResponse.ValidationErrors.filter((x) => x.Key == "Address")[0].Value }}</span>
          </div>
        </div>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" class="px-5 py-3" @click="updateInvoiceInfo">Kaydet</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :title="!isWarehouse ? 'Teslimat Noktası Ekle' : 'Depo Ekle'" :visible.sync="depotDialog" :before-close="clearDepotResponse">
      <span>
        <div class="row">
          <div class="col-lg-6 mb-2">
            <div class="mb-3">
              <label class="text-label form-label">{{ !isWarehouse ? "Teslimat Noktası Adı" : "Depo Adı" }}</label>
              <el-input
                class="form-control"
                :class="{
                  'input-validation-error': depotResponse.ValidationErrors.some((x) => x.Key == 'Title'),
                }"
                :placeholder="!isWarehouse ? 'Teslimat Noktası Adını Giriniz' : 'Depo Adını Giriniz'"
                v-model="WarehouseForm.Title"
              ></el-input>
              <span class="validation-error" v-if="depotResponse.ValidationErrors.some((x) => x.Key == 'Title')">{{ depotResponse.ValidationErrors.filter((x) => x.Key == "Title")[0].Value }}</span>
            </div>
          </div>

          <div class="col-lg-6 mb-2">
            <div class="mb-3">
              <label class="text-label form-label">Telefon</label>
              <el-input
                class="form-control"
                :class="{
                  'input-validation-error': depotResponse.ValidationErrors.some((x) => x.Key == 'Phone'),
                }"
                placeholder="+90 (xxx) xxx xx xx"
                v-mask="['+90 (###) ### ## ##']"
                v-model="WarehouseForm.Phone"
              ></el-input>
              <span class="validation-error" v-if="depotResponse.ValidationErrors.some((x) => x.Key == 'Phone')">{{ depotResponse.ValidationErrors.filter((x) => x.Key == "Phone")[0].Value }}</span>
            </div>
          </div>

          <!-- <div class="col-lg-6 mb-2">
            <div class="mb-3">
              <label class="text-label form-label">İl</label>
              <el-select
                class="default-select form-control wide"
                :class="{
                  'input-validation-error': depotResponse.ValidationErrors.some(
                    (x) => x.Key == 'CityTitle'
                  ),
                }"
                v-model="WarehouseForm.CityyTitle"
                placeholder="..."
              >
                <el-option
                  v-for="item in $store.state.cityDistrict.cities"
                  :key="item.ID"
                  :label="item.Title"
                  :value="item.ID"
                >
                </el-option>
              </el-select>
              <span
                class="validation-error"
                v-if="
                  depotResponse.ValidationErrors.some((x) => x.Key == 'CityTitle')
                "
                >{{
                  depotResponse.ValidationErrors.filter(
                    (x) => x.Key == "CityTitle"
                  )[0].Value
                }}</span
              >
            </div>
          </div>

          <div class="col-lg-6 mb-3">
            <div class="mb-3">
              <label class="text-label form-label"
                >Lütfen Bir İlçe Şeçiniz</label
              >
              <el-select
                class="default-select form-control wide"
                :class="{
                  'input-validation-error': depotResponse.ValidationErrors.some(
                    (x) => x.Key == 'DistrictTitle'
                  ),
                }"
                v-model="WarehouseForm.DistrictTitle"
                placeholder="..."
              >
                <el-option
                  v-for="item in warehouseFormDistricts"
                  :key="item.ID"
                  :label="item.Title"
                  :value="item.ID"
                >
                </el-option>
              </el-select>
              <span
                class="validation-error"
                v-if="
                  depotResponse.ValidationErrors.some(
                    (x) => x.Key == 'DistrictTitle'
                  )
                "
                >{{
                  depotResponse.ValidationErrors.filter(
                    (x) => x.Key == "DistrictTitle"
                  )[0].Value
                }}</span
              >
            </div>
          </div> -->
          <div class="mb-3 col-md-12">
            <label class="form-label">Şehir</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': depotResponse.ValidationErrors.some((x) => x.Key == 'City'),
              }"
              type="text"
              placeholder="Lütfen Bir Şehir Giriniz"
              v-model="WarehouseForm.City"
            >
            </el-input>
            <span class="validation-error" v-if="depotResponse.ValidationErrors.some((x) => x.Key == 'City')">{{ depotResponse.ValidationErrors.filter((x) => x.Key == "City")[0].Value }}</span>
          </div>
          <div class="mb-3 col-md-12">
            <label class="form-label">İlçe</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': depotResponse.ValidationErrors.some((x) => x.Key == 'District'),
              }"
              type="text"
              placeholder="Lütfen Bir İlçe Giriniz"
              v-model="WarehouseForm.District"
            >
            </el-input>
            <span class="validation-error" v-if="depotResponse.ValidationErrors.some((x) => x.Key == 'District')">{{ depotResponse.ValidationErrors.filter((x) => x.Key == "District")[0].Value }}</span>
          </div>
          <div class="mb-3 col-md-12">
            <label class="form-label">Açık Adres</label>
            <input type="text" placeholder="Lütfen Bir Adres Giriniz" class="form-control open-address p-3" v-model="location" @keyup="search" :aria-expanded="places.length > 0 ? true : false" aria-controls="places-dropdown" />
            <ul role="menu" class="place-list" v-if="places.length > 0">
              <li role="menuitem" class="place place-list-item" v-for="place in places" :key="place.place_id" @click="selectPlace(place)">
                {{ place.description }}
              </li>
            </ul>
            <span class="validation-error" v-if="depotResponse.ValidationErrors.some((x) => x.Key == 'Address')">{{ depotResponse.ValidationErrors.filter((x) => x.Key == "Address")[0].Value }}</span>
          </div>
          <div class="col-md-12 mb-2">
            <div class="mb-3">
              <label class="text-label form-label">Konum</label>
              <GmapMap :center="center" :zoom="12" style="width: 100%; height: 400px" :options="mapOptions" ref="mapRef" @click="handleMapClick">
                <GmapMarker :position="marker.position" :clickable="true" :draggable="true" @drag="handleMarkerDrag" @click="panToMarker" />
              </GmapMap>
            </div>
          </div>
        </div>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" class="py-3 px-5" @click="updateWarehouse">Kaydet</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="vehicleDialog" :title="'Araç'" :visible.sync="vehicleDialog">
      <el-steps class="mb-5" :active="active" finish-status="success" style="display: none">
        <el-step></el-step>
        <el-step></el-step>
      </el-steps>

      <div class="row" v-if="active === 1">
        <div class="col-md-6 d-flex justify-content-center" @click="selectVehicleType(1)">
          <a class="p-3 d-flex flex-column text-center w-100 h-100" href="javascript:;">
            <img class="p-4 border border-radius bg-light mb-3" style="min-height: 150px" src="../../assets/images/kamyon.svg" alt="" />
            <span class="text-white">Kamyon</span>
          </a>
        </div>

        <div class="col-md-6 d-flex justify-content-center" @click="selectVehicleType(0)">
          <a class="p-3 d-flex flex-column text-center w-100 h-100" href="javascript:;">
            <img class="p-4 border border-radius bg-light mb-3" style="min-height: 150px" src="../../assets/images/tir.svg" alt="" />
            <span class="text-white">Tır</span>
          </a>
        </div>
        <div class="col-md-6 d-flex justify-content-center" @click="selectVehicleType(2)">
          <a class="p-3 d-flex flex-column text-center w-100 h-100" href="javascript:;">
            <img class="p-4 border border-radius bg-light mb-3" style="min-height: 150px" src="../../assets/images/kamyonet-kasali.svg" alt="" />
            <span class="text-white">Kamyonet</span>
          </a>
        </div>
      </div>

      <div class="row" :style="isUpdate ? 'pointer-events: none' : ''" v-if="active === 2">
        <div class="col-lg-12 mb-2">
          <div class="mb-3">
            <label class="text-label form-label">{{ getVehicleName() }} Ruhsatı Seri No</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'PermitSerialNo'),
              }"
              placeholder="Lütfen Aracın Ruhsat Seri Numarasını Giriniz"
              v-model="VehicleForm.PermitSerialNo"
            ></el-input>
            <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'PermitSerialNo')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "PermitSerialNo")[0].Value }}</span>
          </div>
        </div>

        <div class="col-lg-6 mb-2">
          <div class="mb-3">
            <label class="text-label form-label">Plaka</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'PlateNo'),
              }"
              placeholder="Lütfen Aracınızın Plakasını Giriniz"
              v-model="VehicleForm.PlateNo"
            ></el-input>
            <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'PlateNo')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "PlateNo")[0].Value }}</span>
          </div>
        </div>

        <div class="col-lg-6 mb-2">
          <div class="mb-3">
            <label class="text-label form-label">Basar Tonaj (Kg)</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'TotalWeight'),
              }"
              placeholder="Lütfen Aracınızın Basar Tonajını Giriniz"
              :value="VehicleForm.TotalWeight"
              @input="updateTotalWeight"
              maxlength="6"
            ></el-input>
            <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'TotalWeight')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "TotalWeight")[0].Value }}</span>
          </div>
        </div>

        <div class="col-lg-6 mb-2">
          <div class="mb-3">
            <label class="text-label form-label">Marka</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'Brand'),
              }"
              placeholder="Lütfen Aracın Markasını Giriniz"
              v-model="VehicleForm.Brand"
            ></el-input>
            <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'Brand')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "Brand")[0].Value }}</span>
          </div>
        </div>

        <div class="col-lg-6 mb-3">
          <div class="mb-3">
            <label class="text-label form-label">Model</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'Model'),
              }"
              placeholder="Lütfen Aracın Modelini Giriniz"
              v-model="VehicleForm.Model"
            ></el-input>
            <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'Model')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "Model")[0].Value }}</span>
          </div>
        </div>

        <div class="col-lg-6 mb-2">
          <div class="mb-3 d-flex flex-column">
            <label class="text-label form-label">Yıl</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'Year'),
              }"
              placeholder="Lütfen Aracın Yılını Giriniz"
              maxlength="4"
              :value="VehicleForm.Year"
              @input="updateYear"
            ></el-input>
            <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'Year')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "Year")[0].Value }}</span>
          </div>
        </div>

        <div class="col-lg-6 mb-2">
          <div class="mb-3">
            <label class="text-label form-label">{{ "Dorse Tipi" }}</label>
            <div @click="dorseDialog = true" class="form-control d-flex align-items-center">
              <span style="padding-left: 20px">{{
                VehicleForm.TrailerId
                  ? selectedVehicleType == 0 && $store.state.trailers.truckTrailers.find((x) => x.ID == VehicleForm.TrailerId)
                    ? $store.state.trailers.truckTrailers.find((x) => x.ID == VehicleForm.TrailerId).Title
                    : selectedVehicleType == 1 && $store.state.trailers.lorryTrailers.find((x) => x.ID == VehicleForm.TrailerId)
                    ? $store.state.trailers.lorryTrailers.find((x) => x.ID == VehicleForm.TrailerId).Title
                    : selectedVehicleType == 2 && $store.state.trailers.pickupTrailers.find((x) => x.ID == VehicleForm.TrailerId)
                    ? $store.state.trailers.pickupTrailers.find((x) => x.ID == VehicleForm.TrailerId)
                    : "Geçersiz"
                  : "Seçiniz"
              }}</span>
            </div>
            <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'TrailerId')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "TrailerId")[0].Value }}</span>
          </div>
        </div>

        <div class="col-lg-6 mb-2" v-if="selectedVehicleType == 0">
          <div class="mb-3">
            <label class="text-label form-label">Dorse Ruhsatı Seri No</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'TrailerSerialNo'),
              }"
              placeholder="Lütfen Dorsenin Seri Numarasını Giriniz"
              v-model="VehicleForm.TrailerSerialNo"
            ></el-input>
            <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'TrailerSerialNo')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "TrailerSerialNo")[0].Value }}</span>
          </div>
        </div>

        <div class="col-lg-6 mb-2" v-if="selectedVehicleType == 0">
          <div class="mb-3">
            <label class="text-label form-label">Dorse Plaka</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'TrailerPlateNo'),
              }"
              placeholder="Lütfen Dorsenin Plakasını Giriniz"
              v-model="VehicleForm.TrailerPlateNo"
            ></el-input>
            <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'TrailerPlateNo')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "TrailerPlateNo")[0].Value }}</span>
          </div>
        </div>

        <div class="col-lg-6 mb-2">
          <div class="mb-3">
            <label class="text-label form-label">Şoför Ad</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'FirstName'),
              }"
              placeholder="Lütfen Şoförün Adını Soyadını Giriniz"
              v-model="VehicleForm.FirstName"
            ></el-input>
            <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'FirstName')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "FirstName")[0].Value }}</span>
          </div>
        </div>

        <div class="col-lg-6 mb-2">
          <div class="mb-3">
            <label class="text-label form-label">Şoför Soyad</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'LastName'),
              }"
              placeholder="Lütfen Şoförün Adını Soyadını Giriniz"
              v-model="VehicleForm.LastName"
            ></el-input>
            <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'LastName')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "LastName")[0].Value }}</span>
          </div>
        </div>

        <div class="col-lg-6 mb-2">
          <div class="mb-3">
            <label class="text-label form-label">Doğum Tarihi</label>
            <el-date-picker
              v-model="VehicleForm.BirthDate"
              type="date"
              placeholder="Doğum Tarihinizi Seçiniz"
              class="form-control w-100"
              :class="{
                'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'BirthDate'),
              }"
            >
            </el-date-picker>
            <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'BirthDate')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "BirthDate")[0].Value }}</span>
          </div>
        </div>

        <div class="col-lg-6 mb-2">
          <label class="form-label">Şoför Tc No</label>
          <el-input
            class="form-control"
            :class="{
              'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'DriverTC'),
            }"
            :value="VehicleForm.DriverTC"
            @input="updateDriverTC"
            maxlength="11"
            placeholder="Lütfen 11 Haneli Tc Kimlik No Giriniz"
          ></el-input>
          <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'DriverTC')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "DriverTC")[0].Value }}</span>
        </div>

        <div class="col-lg-6 mb-2">
          <label class="form-label">Şoför Ehliyet Belge No</label>
          <el-input
            class="form-control"
            :class="{
              'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'DriverLicenceNo'),
            }"
            placeholder="Lütfen Ehliyet Belge Numarasını Giriniz"
            v-model="VehicleForm.DriverLicenceNo"
          ></el-input>
          <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'DriverLicenceNo')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "DriverLicenceNo")[0].Value }}</span>
        </div>

        <div class="col-lg-6 mb-2">
          <label class="form-label">Şoför Telefon No</label>
          <el-input
            class="form-control w-100 bg-light"
            :class="{
              'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'DriverPhone'),
            }"
            v-mask="['+90(###) ### ## ##']"
            placeholder="+90 (xxx) xxx xx xx"
            v-model="VehicleForm.DriverPhone"
          ></el-input>
          <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'DriverPhone')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "DriverPhone")[0].Value }}</span>
        </div>

        <div class="col-12 mt-4">
          <div class="bg-light d-flex align-items-center p-3 border border-radius">
            <div class="me-4">
              <el-upload
                :action="'https://api.nakliyekullan.com/' +  'Account/UploadImage'"
                :headers="{
                  Authorization: 'Bearer ' + token,
                }"
                :show-file-list="false"
                class="avatar-uploader"
                :class="{
                  'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'TruckTrailerImage'),
                }"
                list-type="picture-card"
                :on-success="handleTruckImageUploadSuccess"
              >
                <img v-if="VehicleForm.TruckTrailerImage" :src="VehicleForm.TruckTrailerImage" class="avatar-sm" />
                <i v-else class="el-icon-plus"></i>
              </el-upload>

              <el-dialog :close-on-click-modal="false" :visible.sync="imgDialog">
                <img width="100%" :src="VehicleForm.TruckTrailerImage" alt="" />
              </el-dialog>
            </div>
            <div>
              <span class="fw-bold text-white">Çekici Kafa Fotoğrafı</span><br />
              <span class="text-white">Çekici kafa fotoğrafını bu alana .JPEG ya da .PNG formatında yükleyebilirsiniz.</span>
            </div>
          </div>

          <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'TruckTrailerImage')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "TruckTrailerImage")[0].Value }}</span>
        </div>

        <div class="col-12 mt-4">
          <div class="bg-light d-flex align-items-center p-3 border border-radius">
            <div class="me-4">
              <el-upload
                class="avatar-uploader"
                :class="{
                  'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'TrailerImage'),
                }"
                :show-file-list="false"
                :action="'https://api.nakliyekullan.com/' +  'Account/UploadImage'"
                :headers="{
                  Authorization: 'Bearer ' + token,
                }"
                list-type="picture-card"
                :on-success="handleTrailerImageUploadSuccess"
              >
                <img v-if="VehicleForm.TrailerImage" :src="VehicleForm.TrailerImage" class="avatar-sm" />
                <i v-else class="el-icon-plus"></i>
              </el-upload>
            </div>
            <div>
              <span class="fw-bold text-white">Dorse Fotoğrafı</span><br />
              <span class="text-white">Dorse fotoğrafını bu alana .JPEG ya da .PNG formatında yükleyebilirsiniz.</span>
            </div>
          </div>

          <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'TrailerImage')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "TrailerImage")[0].Value }}</span>
        </div>

        <div class="col-12 mt-4">
          <div class="bg-light d-flex align-items-center p-3 border border-radius">
            <div class="me-4">
              <el-upload
                class="avatar-uploader"
                :class="{
                  'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'DriverImage'),
                }"
                :show-file-list="false"
                :action="'https://api.nakliyekullan.com/' +  'Account/UploadImage'"
                :headers="{
                  Authorization: 'Bearer ' + token,
                }"
                list-type="picture-card"
                :on-success="handleDriverImageUploadSuccess"
              >
                <img v-if="VehicleForm.DriverImage" :src="VehicleForm.DriverImage" class="avatar-sm" />
                <i v-else class="el-icon-plus"></i>
              </el-upload>
            </div>
            <div>
              <span class="fw-bold text-white">Şoför Fotoğrafı</span><br />
              <span class="text-white">Şoför fotoğrafını bu alana .JPEG ya da .PNG formatında yükleyebilirsiniz.</span>
            </div>
          </div>

          <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'DriverImage')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "DriverImage")[0].Value }}</span>
        </div>

        <div class="col-12 mt-4">
          <div class="bg-light d-flex align-items-center p-3 border border-radius">
            <div class="me-4">
              <el-upload
                class="avatar-uploader"
                :class="{
                  'input-validation-error': vehicleResponse.ValidationErrors.some((x) => x.Key == 'DriverProofOfLocation'),
                }"
                :show-file-list="false"
                :action="'https://api.nakliyekullan.com/' +  'Account/UploadImage'"
                :headers="{
                  Authorization: 'Bearer ' + token,
                }"
                list-type="picture-card"
                :on-success="handleLocationImageUploadSuccess"
              >
                <img v-if="VehicleForm.DriverProofOfLocation" :src="VehicleForm.DriverProofOfLocation" class="avatar-sm" />
                <i v-else class="el-icon-plus"></i>
              </el-upload>
            </div>
            <div>
              <span class="fw-bold text-white">Şoför İkametgah Belgesi</span><br />
              <span class="text-white">Şoför İkametgah fotoğrafını bu alana .JPEG ya da .PNG formatında yükleyebilirsiniz. Sadece görevliler tarafından görüntülenecektir.</span>
            </div>
          </div>

          <span class="validation-error" v-if="vehicleResponse.ValidationErrors.some((x) => x.Key == 'DriverProofOfLocation')">{{ vehicleResponse.ValidationErrors.filter((x) => x.Key == "DriverProofOfLocation")[0].Value }}</span>
        </div>
      </div>

      <span v-if="!isUpdate" slot="footer" class="dialog-footer">
        <div class="row">
          <div class="col-6">
            <el-button v-if="!isUpdate" class="py-3 w-100" @click="next" :style="active == 1 ? 'display:none' : null">{{ nextbtn }}</el-button>
          </div>
          <div class="col-6">
            <el-button v-if="this.active === 2" :loading="loading" class="py-3 w-100" @click="updateVehicle">Kaydet</el-button>
          </div>
        </div>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Yeni Şifre Oluşturma" :before-close="handlePasswordDialog" :visible.sync="passwordDialog">
      <p class="text-white">
        Lütfen alttaki kutulara yeni şifrenizi giriniz. (Not almanızı öneririz)
      </p>
      <div class="mb-3">
        <label for="currentPassword">Eski Şifre</label>
        <div
          class="form-control el-input"
          :class="{
            'input-validation-error': changePasswordResponse.ValidationErrors.some((x) => x.Key == 'CurrentPassword'),
          }"
        >
          <input id="password" class="el-input__inner" placeholder="******" name="currentPassword" v-model="currentPassword" type="password" />
          <i @click="showPassword" class="bi bi-eye-slash password-icon" id="togglePassword"></i>
        </div>
        <span class="validation-error" v-if="changePasswordResponse.ValidationErrors.some((x) => x.Key == 'CurrentPassword')">{{ changePasswordResponse.ValidationErrors.filter((x) => x.Key == "CurrentPassword")[0].Value }}</span>
      </div>
      <div class="mb-3">
        <label for="newpassword">Yeni Şifre</label>
        <div
          class="form-control el-input"
          :class="{
            'input-validation-error': changePasswordResponse.ValidationErrors.some((x) => x.Key == 'NewPassword'),
          }"
        >
          <input id="passwordNew" class="el-input__inner" placeholder="******" name="newpassword" v-model="newpassword" type="password" />
          <i @click="showPasswordNew" class="bi bi-eye-slash password-icon" id="togglePasswordNew"></i>
        </div>
        <span class="validation-error" v-if="changePasswordResponse.ValidationErrors.some((x) => x.Key == 'NewPassword')">{{ changePasswordResponse.ValidationErrors.filter((x) => x.Key == "NewPassword")[0].Value }}</span>
      </div>
      <div>
        <label for="newpassword2">Yeni Şifre Tekrar</label>
        <div
          class="form-control el-input"
          :class="{
            'input-validation-error': changePasswordResponse.ValidationErrors.some((x) => x.Key == 'NewPasswordRetry'),
          }"
        >
          <input id="passwordNewRetry" class="el-input__inner" placeholder="******" name="newpassword2" v-model="newpassword2" type="password" />
          <i @click="showPasswordNewRetry" class="bi bi-eye-slash password-icon" id="togglePasswordNewRetry"></i>
        </div>
        <span class="validation-error" v-if="changePasswordResponse.ValidationErrors.some((x) => x.Key == 'NewPasswordRetry')">{{ changePasswordResponse.ValidationErrors.filter((x) => x.Key == "NewPasswordRetry")[0].Value }}</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" class="w-100" @click="updatePassword">Devam Et</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="signOutDialog" title="Çıkış Yap" :visible.sync="signOutDialog">
      <h6>Uygulamadan çıkış yapmak istediğinize emin misiniz?</h6>
      <span class="text-white">Çıkış yaptıktan sonra yine aynı e-posta ve şifre ile giriş yapabilirsiniz. Verilerinizin silinmesini ya da bilgi almak istiyorsanız destek@test.nakliyekullan.com ile iletişime geçebilirsiniz</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="bg-transparent" @click="signOutDialog = false">İptal</el-button>
        <el-button :loading="loading" @click="signOut">Çıkış Yap</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="Dorse Tipi Seçimi" :visible.sync="dorseDialog">
      <div class="row" v-if="selectedVehicleType == 0">
        <div class="col-md-4 mb-3 dorse-type" v-for="(item, index) in $store.state.trailers.truckTrailers" :key="index" @click="selectTrailerType(item.ID)">
          <img :src="item.Image" alt="" /> <br />
          <span class="text-white mt-2">{{ item.Title }}</span>
        </div>
      </div>
      <div class="row" v-if="selectedVehicleType == 1">
        <div class="col-md-4 mb-3 dorse-type" v-for="(item, index) in $store.state.trailers.lorryTrailers" :key="index" @click="selectTrailerType(item.ID)">
          <img :src="item.Image" alt="" /> <br />
          <span class="text-white mt-2">{{ item.Title }}</span>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-4 mb-3 dorse-type" v-for="(item, index) in $store.state.trailers.pickupTrailers" :key="index" @click="selectTrailerType(item.ID)">
          <img :src="item.Image" alt="" /> <br />
          <span class="text-white mt-2">{{ item.Title }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Sil" :visible.sync="deleteDialog">
      <p class="text-white">Bu <span v-if="isWarehouse">depoyu</span> <span v-else>teslimat adresini</span> silmek istediğinize emin misiniz?</p>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="bg-transparent py-3 px-5"
          @click="
            () => {
              warehouseId = null;
              deleteDialog = false;
            }
          "
          >İptal</el-button
        >
        <el-button :loading="loading" class="bg-pink py-3 px-5" @click="deleteWarehouse(warehouseId)">Sil</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Sil" :visible.sync="deleteVehicleDialog">
      <p class="text-white">Bu aracı silmek istediğinize emin misiniz?</p>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="bg-transparent py-3 px-5"
          @click="
            () => {
              vehicleId = null;
              deleteVehicleDialog = false;
            }
          "
          >İptal</el-button
        >
        <el-button :loading="loading" class="bg-pink py-3 px-5" @click="deleteVehicle(vehicleId)">Sil</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Engeli Kaldır" :visible.sync="removeBlockDialog">
      <p class="text-white">
        Bu kullanıcının engelini kaldırmak istediğinize emin misiniz?
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="bg-transparent py-3 px-5"
          @click="
            () => {
              currentBlockedUserId = null;
              removeBlockDialog = false;
            }
          "
          >İptal</el-button
        >
        <el-button :loading="loading" class="bg-pink py-3 px-5" @click="removeBlock">Kaldır</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deepCopyObject } from "../../helpers/generalHelper";
import META_DATA from "../../constants/metaData";
import notFoundInfo from "../../components/notFound/notFoundInfo.vue";
import geocoder from "geocoder";
import { mapGetters } from "vuex";
export default {
  components: { notFoundInfo },
  metaInfo() {
    return {
      title: META_DATA.PROFILE.Title,
    };
  },
  data() {
    return {
      location: "",
      places: [],
      selected: {},
      service: null,
      apiKey: "AIzaSyCjF3pCyknt07yWnY9N9d9MIPXkyZinAfg",
      step: 0,
      changePhotoDialog: false,
      deleteVehicleDialog: false,
      vehicleId: null,
      selectedVehicleType: null,
      firstRender: true,
      firstRenderBillDistrict: true,
      firstRenderWarehouseDistrict: true,
      isWarehouse: null,
      marker: {
        position: {
          lat: 45.508,
          lng: -73.587,
        },
      },
      mapOptions: {
        disableDefaultUI: true,
      },
      center: { lat: 45.508, lng: -73.587 },
      invoiceDialog: false,
      deleteDialog: false,
      passwordDialog: false,
      signOutDialog: false,
      dorseDialog: false,
      imageUrl: "",
      invitecode: "",
      currentPassword: "",
      newpassword: "",
      newpassword2: "",
      dialogImageUrl: "",
      imgDialog: false,
      vehicleDialog: false,
      active: 1,
      blockedUsers: [],
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
      ],
      value: "",
      nextbtn: "Geri",
      personalInfoDistricts: null,
      billFormDistricts: null,
      BillForm: {
        CompanyLogo: null,
        Title: null,
        Email: null,
        PhoneNumber: null,
        CityTitle: null,
        DistrictTitle: null,
        TaxNo: null,
        TaxOffice: null,
        Address: null,
      },
      warehouses: null,
      WarehouseForm: {
        a: null,
        Title: null,
        City: null,
        District: null,
        Lat: 0,
        Lon: 0,
        Address: null,
        Phone: null,
      },
      warehouseFormDistricts: null,
      NotificationSettingsInputs: {},
      VehicleForm: {
        VehicleId: null,
        PermitSerialNo: null,
        PlateNo: null,
        TotalWeight: null,
        Brand: null,
        Model: null,
        Year: null,
        TrailerPlateNo: null,
        TrailerSerialNo: null,
        FirstName: null,
        LastName: null,
        BirthDate: null,
        DriverTC: null,
        DriverLicenceNo: null,
        DriverPhone: null,
        TruckTrailerImage: null,
        TrailerImage: null,
        DriverImage: null,
        DriverProofOfLocation: null,
        TrailerId: null,
      },
      userFormInputs: {
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        CityTitle: null,
        DistrictTitle: null,
        WorkCityId: null,
        TargetWorkCityId: null,
        VehicleType: 0,
        Email: "",
        BankAccount: {},
        Address: "",
      },
      depotDialog: false,
      removeBlockDialog: false,
      depotResponse: { ValidationErrors: [] },
      resetObj: { ValidationErrors: [] },
      isUpdate: false,
    };
  },
  async created() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.tab) {
      this.step = parseInt(params.tab);
      this.isWarehouse = this.step === 1 ? true : false;
    }
    await this.$store.dispatch("getCities");
    await this.$store.dispatch("getTrailers");
    this.token = this.$store.getters["getToken"];
    this.blockedUsers = this.user.BlockedUsers;
    this.BillForm = deepCopyObject(this.$store.getters["userState"].InvoiceInfo);
    // const result = await this.$store.dispatch("getDistrict", this.user.CityTitle);

    // if (result)
    this.userFormInputs = deepCopyObject(this.user);
  
    console.log("this.$store.state.User: ", this.$store.state.User);
    this.warehouse = this.$store.state.User.warehouses;
    this.NotificationSettingsInputs = deepCopyObject(this.user.NotificationSettings);
    // this.personalInfoDistricts = await this.$getDistricts(
    //   this.userFormInputs.CityTitle
    // );

    // control invoice query
    if (this.$route.query.invoiceDialog === true) this.invoiceDialog = true;
  },
  async mounted() {
    // this.MapsInit();
    this.$root.$emit("openAddDepotModal", () => {
      this.depotDialog = true;
    });
   
  },
  destroyed() {
    this.resetResponses();
  },
  computed: {
    ...mapGetters({
      cityState: "cityState",
    }),
    cityInfo() {
      return this.$store.getters["cityInfo"];
    },
    token: {
      get() {
        return localStorage.getItem("token");
      },
      set(newValue) {
        return newValue;
      },
    },
    vehicleResponse: {
      get() {
        return this.$store.getters["vehicleResponse"];
      },
      set(newValue) {
        this.$store.commit("vehicleResponse", newValue);
      },
    },
    inviteFriendResponse: {
      get() {
        return this.$store.getters["inviteFriendResponse"];
      },
      set(newValue) {
        this.$store.commit("inviteFriendResponse", newValue);
      },
    },
    warehouseId: {
      get() {
        return this.$store.getters["warehouseId"];
      },
      set(newValue) {
        this.$store.commit("warehouseId", newValue);
      },
    },
    bankResponse: {
      get() {
        return this.$store.getters["bankResponse"];
      },
      set(newValue) {
        this.$store.commit("bankResponse", newValue);
      },
    },
    userResponse: {
      get() {
        return this.$store.getters["userResponse"];
      },
      set(newValue) {
        this.$store.commit("userResponse", newValue);
      },
    },
    invoiceResponse: {
      get() {
        return this.$store.getters["invoiceResponse"];
      },
      set(newValue) {
        this.$store.commit("invoiceResponse", newValue);
      },
    },
    changePasswordResponse: {
      get() {
        return this.$store.getters["changePasswordResponse"];
      },
      set(newValue) {
        this.$store.commit("changePasswordResponse", newValue);
      },
    },
    user: {
      get() {
        return this.$store.getters["userState"];
      },
      set(newValue) {
        this.$store.commit("userCommit", newValue);
      },
    },
    loading() {
      return this.$store.getters["loading"];
    },
  },
  watch: {
    location: function() {
      if (this.service === null) {
        this.MapsInit();
      }
      if (this.location.length === 0) {
        this.clearForm();
      }
    },
    WarehouseForm: function() {
      console.log("this.WarehouseForm change", this.WarehouseForm);
    },
    // "BillForm.CityTitle": async function () {
    //   this.billFormDistricts = await this.$getDistricts(this.BillForm.CityTitle);
    //   if (!this.firstRenderBillDistrict) {
    //     this.BillForm.DistrictTitle = null;
    //   }
    //   this.firstRenderBillDistrict = false;
    // },
    // "WarehouseForm.CityyTitle": async function () {
    //   if (this.WarehouseForm.CityyTitle) {
    //     const cityInfo = await this.$store.dispatch("getCity", {
    //       Id: this.WarehouseForm.CityyTitle,
    //       ThrowNotFoundIfNull: true,
    //     });
    //     if (!cityInfo.HasError) {
    //       this.center.lat = parseFloat(cityInfo.Data.Latitude);
    //       this.center.lng = parseFloat(cityInfo.Data.Longitude);
    //     }
    //     this.warehouseFormDistricts = await this.$getDistricts(
    //       this.WarehouseForm.CityyTitle
    //     );
    //     if (!this.firstRenderWarehouseDistrict) {
    //       this.WarehouseForm.DistrictTitle = null;
    //     }
    //     this.firstRenderWarehouseDistrict = false;
    //   }
    // },
    NotificationSettingsInputs: {
      handler: function() {
        if (!this.firstRender) {
          this.$store.dispatch("updateNotificationSettingsCareer", this.NotificationSettingsInputs);
        }
        this.firstRender = false;
      },
      deep: true,
    },
    step: {
      handler: function() {
        this.resetResponses();
      },
      deep: true,
    },
  },
  methods: {
    filteredCities() {
      return this.cityState?.filter((item) => item && item.ID);
    },
    getVehicleName() {
      if (this.selectedVehicleType == 0) return "Tır";
      if (this.selectedVehicleType == 1) return "Kamyon";
      if (this.selectedVehicleType == 2) return "Kamyonet";
    },
    MapsInit: function() {
      this.service = new window.google.maps.places.AutocompleteService();
    },
    search: async function() {
      try {
        const results = await this.service.getPlacePredictions({
          input: this.location,
          // types: ["(cities)"],
          componentRestrictions: {
            country: "tr",
          },
        });
        this.places = results.predictions;
      } catch (error) {
        console.log("error search: ", error);
      }
    },
    getLatLong: function(location) {
      try {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
          {
            placeId: location,
          },
          async (results, status) => {
            if (status == window.google.maps.GeocoderStatus.OK) {
              this.marker.position.lat = await results[0].geometry.location.lat();
              this.marker.position.lng = await results[0].geometry.location.lng();
              this.center = {
                lat: this.marker.position.lat,
                lng: this.marker.position.lng,
              };
            }
          },
        );
      } catch (error) {
        console.log(`error getLatLong: ${error}`);
      }
    },
    selectPlace: function(place) {
      this.location = place.description;
      this.selected.description = place.description;
      this.selected.location_id = place.place_id;
      this.getLatLong(place.place_id);
      this.places = [];
    },
    clearForm: function() {
      this.location = "";
      this.selected = {};
      this.places = [];
      this.$emit("selectedArea", {});
      this.geolocate();
    },
    handleChangePhoto(data) {
      if (!data.HasError) {
        var req = {
          FirstName: this.userFormInputs.FirstName,
          LastName: this.userFormInputs.LastName,
          PhoneNumber: this.$phoneNumberFormatString(this.userFormInputs.PhoneNumber),
          // CityTitle: this.userFormInputs.CityTitle,
          // DistrictTitle: this.userFormInputs.DistrictTitle,
          WorkCityId: this.userFormInputs.WorkCityId,
          TargetWorkCityId: this.userFormInputs.TargetWorkCityId,
          VehicleType: this.userFormInputs.VehicleType,
          Email: this.userFormInputs.Email,
          ImageUrl: data.Data.Url,
        };
        this.$store.dispatch("updateUserInfo", req);
        window.sendNotification.success(data.Message);
      } else window.sendNotification.error(data.Message);
    },
    updateTotalWeight(e) {
      this.VehicleForm.TotalWeight = e.replace(/[^\d]/g, "");
    },
    updateYear(e) {
      this.VehicleForm.Year = e.replace(/[^\d]/g, "");
    },
    updateDriverTC(e) {
      this.VehicleForm.DriverTC = e.replace(/[^\d]/g, "");
    },
    handlePasswordDialog() {
      this.changePasswordResponse = { ValidationErrors: [] };
      this.passwordDialog = false;
    },
    getCopiedObject(item) {
      return deepCopyObject(item);
    },
    showPassword() {
      const passwordType = this.$("#password").attr("type");
      if (passwordType === "text") {
        this.$("#togglePassword").removeClass("bi-eye");
        this.$("#togglePassword").addClass("bi-eye-slash");
        this.$("#password").attr("type", "password");
      } else if (passwordType === "password") {
        this.$("#togglePassword").removeClass("bi-eye-slash");
        this.$("#togglePassword").addClass("bi-eye");
        this.$("#password").attr("type", "text");
      }
    },
    showPasswordNew() {
      const passwordNewType = this.$("#passwordNew").attr("type");
      if (passwordNewType === "text") {
        this.$("#togglePasswordNew").removeClass("bi-eye");
        this.$("#togglePasswordNew").addClass("bi-eye-slash");
        this.$("#passwordNew").attr("type", "password");
      } else if (passwordNewType === "password") {
        this.$("#togglePasswordNew").removeClass("bi-eye-slash");
        this.$("#togglePasswordNew").addClass("bi-eye");
        this.$("#passwordNew").attr("type", "text");
      }
    },
    showPasswordNewRetry() {
      const passwordNewRetryType = this.$("#passwordNewRetry").attr("type");
      if (passwordNewRetryType === "text") {
        this.$("#togglePasswordNewRetry").removeClass("bi-eye");
        this.$("#togglePasswordNewRetry").addClass("bi-eye-slash");
        this.$("#passwordNewRetry").attr("type", "password");
      } else if (passwordNewRetryType === "password") {
        this.$("#togglePasswordNewRetry").removeClass("bi-eye-slash");
        this.$("#togglePasswordNewRetry").addClass("bi-eye");
        this.$("#passwordNewRetry").attr("type", "text");
      }
    },
    clearDepotResponse() {
      this.depotResponse = this.resetObj;
      this.depotDialog = false;
      this.location = "";
    },
    clearInvoiceResponse() {
      this.invoiceResponse = this.resetObj;
      this.BillForm = deepCopyObject(this.$store.getters["userState"].InvoiceInfo);
      this.invoiceDialog = false;
    },
    resetResponses() {
      this.vehicleResponse = this.resetObj;
      this.bankResponse = this.resetObj;
      this.depotResponse = this.resetObj;
      this.userResponse = this.resetObj;
      this.invoiceResponse = this.resetObj;
      this.changePasswordResponse = this.resetObj;
      this.inviteFriendResponse = this.resetObj;
    },
    handleRemoveBlock(userId) {
      this.currentBlockedUserId = userId;
      this.removeBlockDialog = true;
    },
    removeBlock() {
      this.$store.dispatch("removeBlock", {
        UserId: this.currentBlockedUserId,
      });
      this.removeBlockDialog = false;
    },
    async handleCityChange(CityTitle) {
      this.personalInfoDistricts = await this.$getDistricts(CityTitle);
      if (!this.firstRenderUserDistrict) {
        this.userFormInputs.DistrictTitle = null;
      }
      this.firstRenderUserDistrict = false;
    },
    async inviteFriend() {
      this.$store.dispatch("inviteFriend", {
        Email: this.invitecode,
      });
    },
    deleteVehicle(vehicleId) {
      this.$store.dispatch("deleteVehicle", { Id: vehicleId });
      this.deleteVehicleDialog = false;
    },
    selectTrailerType(TrailerId) {
      this.VehicleForm.TrailerId = TrailerId;
      this.dorseDialog = false;
    },
    handleTruckImageUploadSuccess(res) {
      if (res.HasError) {
        window.sendNotification.error(res.Message);
      } else {
        this.VehicleForm.TruckTrailerImage = res.Data.Url;
      }
    },
    handleTrailerImageUploadSuccess(res) {
      if (res.HasError) {
        window.sendNotification.error(res.Message);
      } else {
        this.VehicleForm.TrailerImage = res.Data.Url;
      }
    },
    handleDriverImageUploadSuccess(res) {
      if (res.HasError) {
        window.sendNotification.error(res.Message);
      } else {
        this.VehicleForm.DriverImage = res.Data.Url;
      }
    },
    handleLocationImageUploadSuccess(res) {
      if (res.HasError) {
        window.sendNotification.error(res.Message);
      } else {
        this.VehicleForm.DriverProofOfLocation = res.Data.Url;
      }
    },
    async updateVehicle() {
      this.VehicleForm.Type = this.selectedVehicleType;
      const result = await this.$store.dispatch("updateVehicle", {
        VehicleForm: this.VehicleForm,
      });
      if (!result.HasError) this.vehicleDialog = false;
    },
    addVehicleDialog() {
      this.active = 1;
      this.vehicleDialog = true;
      this.isUpdate = false;
      this.VehicleForm = {
        VehicleId: null,
        PermitSerialNo: null,
        PlateNo: null,
        TotalWeight: null,
        Brand: null,
        Model: null,
        Year: null,
        TrailerPlateNo: null,
        TrailerSerialNo: null,
        FirstName: null,
        LastName: null,
        BirthDate: null,
        DriverTC: null,
        DriverLicenceNo: null,
        DriverPhone: null,
        TruckTrailerImage: null,
        TrailerImage: null,
        DriverImage: null,
        DriverProofOfLocation: null,
        TrailerId: null,
      };
    },
    selectVehicleType(type) {
      this.active++;
      this.selectedVehicleType = type;
    },
    deleteWarehouse(warehouse) {
      this.$store.dispatch("deleteWarehouse", {
        Id: { Id: warehouse },
        isWarehouse: this.isWarehouse,
      });

      this.deleteDialog = false;
    },
    newWarehouse() {
      this.depotDialog = true;
      this.warehouseFormDistricts = null;
      this.WarehouseForm = {
        Title: null,
        City: null,
        District: null,
        Lat: 0,
        Lon: 0,
        Address: null,
        Phone: null,
      };
      this.geolocate();
    },
    // eslint-disable-next-line no-debugger
    selectWarehouse(warehouse) {
      this.marker.position.lng = warehouse.Lon;
      this.marker.position.lat = warehouse.Lat;
      this.center.lat = warehouse.Lat;
      this.center.lng = warehouse.Lon;
      this.depotDialog = true;
      this.location = warehouse.Address;
      warehouse.City = warehouse.CityTitle;
      warehouse.District = warehouse.DistrictTitle;
      this.WarehouseForm = deepCopyObject(warehouse);
    },

    async updateWarehouse() {
      this.WarehouseForm.Phone = this.$phoneNumberFormatString(this.WarehouseForm.Phone);
      this.WarehouseForm.Address = this.location;
      const result = await this.$store.dispatch("updateWarehouse", {
        isWarehouse: this.isWarehouse,
        WarehouseForm: this.WarehouseForm,
        lat: this.marker.position.lat,
        lng: this.marker.position.lng,
      });
      if (!result.HasError) this.depotDialog = false;
      this.depotResponse = result;
    },
    updateBankInfo() {
      // if (this.userFormInputs.BankAccount.IBAN.length < 27)
      //   this.bankResponse = {
      //     ValidationErrors: [
      //       {
      //         Key: "IBAN",
      //         Value: "Lütfen hesap bilgilerinizi doğru giriniz"
      //       },
      //     ],
      //   };
      // else
      this.$store.dispatch("updateBankAccountInfo", {
        Title: this.userFormInputs.BankAccount?.Title,
        IBAN: this.userFormInputs.BankAccount?.IBAN.replace(/\s/g, ""),
      });
    },

    async updateUserInfo() {
      var req = {
        FirstName: this.userFormInputs.FirstName,
        LastName: this.userFormInputs.LastName,
        PhoneNumber: this.$phoneNumberFormatString(this.userFormInputs.PhoneNumber),
        // CityTitle: this.userFormInputs.CityTitle,
        // DistrictTitle: this.userFormInputs.DistrictTitle,
        WorkCityId: this.userFormInputs.WorkCityId,
        TargetWorkCityId: this.userFormInputs.TargetWorkCityId,
        VehicleType: this.userFormInputs.VehicleType,
        Email: this.userFormInputs.Email,
        Address: this.user.InvoiceInfo.Address,
      };
      this.$store.dispatch("updateUserInfo", req);
    },
    async updateInvoiceInfo() {
      const result = await this.$store.dispatch("updateInvoiceInfo", {
        ...this.BillForm,
        PhoneNumber: this.$phoneNumberFormatString(this.BillForm.PhoneNumber),
      });
      if (!result.HasError) {
        this.invoiceDialog = false;
        this.invoiceResponse = { ValidationErrors: [] };
      }
    },
    async updatePassword() {
      var req = {
        CurrentPassword: this.currentPassword,
        NewPassword: this.newpassword,
        NewPasswordRetry: this.newpassword2,
      };
      await this.$store.dispatch("changePassword", req);
      this.passwordDialog = true;
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.marker.position = this.center;
        this.panToMarker();
      });
    },
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      // this.$refs.mapRef.setZoom(18);
    },
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
    async handleMapClick(e) {
      try {
        const data = await geocoder.reverseGeocodeAsync(e.latLng.lat(), e.latLng.lng(), { key: this.apiKey });
        this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        this.center = {
          lat: this.marker.position.lat,
          lng: this.marker.position.lng,
        };
        let address = data.results.find((x) => x.types[0] == "street_address")?.formatted_address;
        if (!address) address = data.results.find((x) => x.types[0] == "route")?.formatted_address;
        if (!address) address = data.results[0].formatted_address;
        this.location = address;
      } catch (error) {
        console.log("err handleMapClick: ", error);
      }

      // this.location = address
    },
    next() {
      if (this.active++ > 1) this.active = 1;

      if (this.active === 2) {
        this.nextbtn = "Geri";
      } else {
        this.nextbtn = "Geri";
      }
    },

    handleAvatarSuccess(res) {
      if (res.HasError) {
        window.sendNotification.error(res.Message);
      } else {
        window.sendNotification.success(res.Message);
        this.BillForm.CompanyLogo = res.Data.Url;
      }
    },

    signOut() {
      this.$store.dispatch("signOutUser");
      this.signOutDialog = false;
      this.$router.push({ path: "/login" });
    },
  },
  beforeAvatarUpload(file) {
    const isJPG = file.type === "image/jpeg";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJPG) {
      window.sendNotification.error("Avatar picture must be JPG format!");
    }
    if (!isLt2M) {
      window.sendNotification.error("Avatar picture size can not exceed 2MB!");
    }
    return isJPG && isLt2M;
  },
};
</script>

<style lang="scss">
@import "./style.scss";
</style>
